<template>
  <div>
    <loader v-if="showLoader" />
    <v-row v-else justify="center" class="px-0">
      <v-col style="color: black; text-align: center" class="mt-3 mt-sm-0">
        <strong style="font-size: 18px">{{
          "checkout_select_account_label" | localize
        }}</strong>
        <v-row
          style="display: flex; flex-direction: column; align-items: center"
          class="mb-3 mb-sm-0"
        >
          <v-radio-group
            hide-details
            :row="$vuetify.breakpoint.smAndDown ? false : true"
            v-model="typeUser"
            class="ragioGroup mb-3"
          >
            <v-radio label="Existing User" value="existing_user">
              <template v-slot:label>
                <div
                  :class="
                    typeUser == 'existing_user'
                      ? 'black--text font-weight-bold'
                      : 'black--text'
                  "
                >
                  <span v-if="!loggedUser">
                    {{ "cart_details_radio_button_existing_user" | localize }}
                  </span>
                  <span v-else>
                    {{ loggedUser.first_name + " " + loggedUser.last_name }}
                  </span>
                </div>
              </template>
            </v-radio>
            <v-radio label="Create a New Account" value="new_user">
              <template v-slot:label>
                <div
                  :class="
                    typeUser == 'new_user'
                      ? 'black--text font-weight-bold'
                      : 'black--text'
                  "
                >
                  {{ "cart_details_label_create_account" | localize }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-row>
        <v-row justify="center" no-gutters>
          <div
            v-if="typeUser == 'new_user'"
            style="width: 600px; text-align: center"
          >
            <v-col
              cols="11"
              xl="10"
              lg="10"
              md="10"
              :class="$vuetify.breakpoint.xs ? 'ml-3' : 'ml-10'"
            >
              <v-row justify="end" :class="$vuetify.breakpoint.xs ? '' : ''">
                <div v-if="!signUpSuccess" style="text-align: left">
                  <v-row>
                    <v-col>
                      <span>
                        {{ "sign_up_placeholder_first_name" | localize }}
                        <span class="req">*</span>
                      </span>
                      <v-text-field
                        outlined
                        class="rounded-lg"
                        dense
                        v-model="user.first_name"
                        :color="firstNameError.length ? '' : '#6ABAA4'"
                        :error-messages="firstNameError | localize"
                        @blur="$v.user.first_name.$touch()"
                      />
                    </v-col>
                    <v-col>
                      <span>
                        {{ "sign_up_placeholder_last_name" | localize }}
                        <span class="req">*</span>
                      </span>
                      <v-text-field
                        outlined
                        class="rounded-lg"
                        dense
                        v-model="user.last_name"
                        :color="lastNameError.length ? '' : '#6ABAA4'"
                        :error-messages="lastNameError | localize"
                        @blur="$v.user.last_name.$touch()"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    :align="
                      $vuetify.breakpoint.md || userLocale == 'es'
                        ? 'end'
                        : 'start'
                    "
                  >
                    <v-col cols="4" class="px-0">
                      <span>
                        {{ "sign_up_placeholder_phone_code" | localize }}
                        <span class="req">*</span>
                      </span>
                      <v-text-field
                        outlined
                        class="rounded-lg"
                        dense
                        v-model="user.phone_code"
                        placeholder="+"
                        v-mask="'+####'"
                        :color="phoneCodeError.length ? '' : '#6ABAA4'"
                        :error-messages="phoneCodeError | localize"
                        @blur="$v.user.phone_code.$touch()"
                        @keypress="isNumber($event)"
                      />
                    </v-col>
                    <v-col
                      cols="8"
                      :style="
                        $vuetify.breakpoint.xs || $vuetify.breakpoint.md
                          ? 'padding-left: 30px'
                          : 'padding-left: 30px'
                      "
                    >
                      <span>
                        {{ "sign_up_placeholder_phone_number" | localize }}
                        <span class="req">*</span>
                      </span>
                      <v-text-field
                        outlined
                        class="rounded-lg"
                        hide-spin-buttons
                        dense
                        v-model="user.phone"
                        type="text"
                        pattern="\d*"
                        v-mask="`${phoneNumberMask}`"
                        @input="numberError = []"
                        :color="phoneNumberError.length ? '' : '#6ABAA4'"
                        :error-messages="
                          phoneNumberError.length > 0
                            ? phoneNumberError
                            : numberError[0] | localize
                        "
                        @keypress="isNumber($event)"
                        @blur="$v.user.phone.$touch()"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    :align="$vuetify.breakpoint.xs ? 'end' : 'start'"
                  >
                    <v-col>
                      <span>
                        {{ "faq_placeholder_email" | localize }}
                        <span class="req">*</span>
                      </span>
                      <v-text-field
                        outlined
                        class="rounded-lg"
                        dense
                        v-model="user.email"
                        :color="emailError.length ? '' : '#6ABAA4'"
                        @input="authEmailError = []"
                        :error-messages="
                          emailError.length > 0
                            ? emailError
                            : authEmailError.length
                            ? authEmailError[0]
                            : '' | localize
                        "
                        @blur="$v.user.email.$touch()"
                      />
                    </v-col>
                    <!-- <v-col class="ml-5">
                      <span>{{
                        "cart_details_placeholder_confirm_email" | localize
                      }}<span class="req">*</span></span>
                      <v-text-field
                        outlined
                        class="rounded-lg"
                        dense
                        v-model="user.confirm_email"
                        :color="confirmEmailError.length ? '' : '#6ABAA4'"
                        :error-messages="confirmEmailError"
                        @blur="$v.user.confirm_email.$touch()"
                    /></v-col> -->
                  </v-row>
                  <span>
                    {{ "sign_in_placeholder_password" | localize }}
                    <span class="req">*</span>
                  </span>
                  <v-text-field
                    outlined
                    class="rounded-lg"
                    dense
                    v-model="user.password"
                    :type="showPassword ? 'text' : 'password'"
                    :color="passwordError.length ? '' : '#6ABAA4'"
                    :error-messages="passwordError | localize"
                    @blur="$v.user.password.$touch()"
                    :append-icon="
                      showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                    "
                    @click:append="showPassword = !showPassword"
                  />
                  <v-row no-gutters>
                    <v-col>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        size="small"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        class="pa-0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span class="label-register">{{
                            "sign_up_placeholder_birth_date" | localize
                          }}</span>
                          <v-text-field
                            class="pa-0 rounded-lg"
                            dense
                            :value="
                              user.birthDate
                                ? new Date(user.birthDate).toLocaleDateString(
                                    'en-GB',
                                    { timeZone: 'Europe/London' }
                                  )
                                : ''
                            "
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            append-icon="mdi-calendar"
                            @click:append="on.click"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :show-current="
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 20
                              )
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          :max="
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 16
                              )
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          :min="
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 100
                              )
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          v-model="user.birthDate"
                          :locale="userLocale"
                          no-title
                          scrollable
                          ref="picker"
                          @change="
                            $refs.menu.save(user.birthDate), (menu = false)
                          "
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            {{ "button_cancel" | localize }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(user.birthDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="ml-5">
                      <span class="label-register">{{
                        "sign_up_placeholder_gender" | localize
                      }}</span>
                      <v-select
                        class="pa-0 rounded-lg"
                        dense
                        :items="Object.values(items)"
                        :item-value="'value'"
                        :item-text="'title'"
                        outlined
                        v-model.trim="user.gender"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-col class="px-0 py-0">
                    <span class="label-register"
                      >{{
                        "cart_details_placeholder_country" | localize
                      }} </span
                    ><span class="req">&nbsp;*</span>
                    <v-autocomplete
                      outlined
                      class="rounded-lg"
                      dense
                      v-model="user.country"
                      background-color="white"
                      :items="Object.values(countryList)"
                      :item-value="'id'"
                      :item-text="'name'"
                      :color="countryError.lenght ? '' : '#6ABAA4'"
                      :error-messages="countryError | localize"
                      @blur="$v.user.country.$touch()"
                    />
                  </v-col>
                  <v-expand-transition>
                    <v-row no-gutters v-if="user.country && isMexico">
                      <span class="label-register" v-if="isMexico">
                        <!-- {{
                        "sign_up_placeholder_hotel" | localize
                      }} -->
                        {{ "cart_details_placeholder_state" | localize
                        }}<span class="req">*</span>
                      </span>
                      <v-col cols="12">
                        <v-autocomplete
                          v-if="isMexico"
                          class="mt-1 rounded-lg iconPass"
                          dense
                          :items="Object.values(states)"
                          :item-value="'id'"
                          :item-text="'name'"
                          :no-data-text="'not_available_hotels' | localize"
                          :menu-props="{
                            bottom: true,
                            offsetY: true,
                            closeOnClick: true,
                          }"
                          outlined
                          v-model.trim="userCountry.state"
                          @blur="$v.userCountry.state.$touch()"
                          :error-messages="stateError | localize"
                        >
                          <!-- <template #selection="{ item }">
                           {{ item.shop_name }} <span class="req" v-if="isMexico"></span>
                          </template> -->
                        </v-autocomplete>
                        <!-- <v-text-field
                          class="mt-1 iconPass"
                          dense
                          outlined
                          hide-details
                          v-model.trim="userCountry.state"
                          :error-messages="isMexico ? stateError : ''"
                          :color="
                            !isMexico && stateError.lenght ? '' : '#6ABAA4'
                          "
                          v-else
                        /> -->
                      </v-col>
                    </v-row>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-row no-gutters v-if="user.country">
                      <span class="label-register">
                        <!-- {{
                        "sign_up_placeholder_hotel" | localize
                      }} -->
                        {{ "sign_up_city_placeholder" | localize }}
                        <span class="req" v-if="isMexico">*</span>
                      </span>
                      <v-col cols="12">
                        <v-text-field
                          class="mt-1 iconPass rounded-lg"
                          dense
                          outlined
                          v-model.trim="userCountry.city"
                          :error-messages="
                            isMexico ? $options.filters.localize(cityError) : ''
                          "
                          @blur="$v.userCountry.city.$touch()"
                          :color="
                            !isMexico && cityError.length ? '' : '#6ABAA4'
                          "
                        >
                          <!-- <template #selection="{ item }">
                            <v-chip small dark color="#E62076">{{ item.shop_name }}</v-chip>
                          </template> -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col class="px-0 py-0" v-if="!localUser">
                      <span class="label-register">{{
                        "sign_up_placeholder_hotel" | localize
                      }}</span>
                      <v-autocomplete
                        dense
                        outlined
                        class="rounded-lg"
                        :items="Object.values(hotel)"
                        :item-value="'uid'"
                        :item-text="'shop_name'"
                        :no-data-text="'not_available_hotels' | localize"
                        v-model="user.hotel"
                        color="#6ABAA4"
                        placeholder="Type here.."
                      />
                    </v-col>
                  </v-expand-transition>
                  <v-row
                    no-gutters
                    justify="center"
                    align="center"
                    :class="
                      $vuetify.breakpoint.xs ? 'px-2 mb-3' : 'px-5 mb-3 ml-3'
                    "
                  >
                    <img
                      class="mr-1"
                      src="@/assets/notChecked.svg"
                      style="cursor: pointer"
                      v-if="isAgree == 0"
                      @click="isAgree = 1"
                    />
                    <img
                      src="@/assets/chekedTerm.svg"
                      v-if="isAgree == 1"
                      class="mr-1"
                      @click="isAgree = 0"
                      style="cursor: pointer"
                    />

                    <span
                      class="aggreEULAText"
                      :style="
                        choseIsAgree
                          ? 'animation: horizontal-snakign 0.35s infinite;'
                          : ''
                      "
                    >
                      {{ "cart_details_label_agree_policy1" | localize }}
                      <span
                        @click="openModal()"
                        style="
                          color: black;
                          font-weight: 700;
                          text-decoration: underline;
                          cursor: pointer;
                        "
                      >
                        {{ "license_short_label" | localize }}
                      </span>
                    </span>
                  </v-row>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-row>

        <v-row justify="center" no-gutters>
          <v-btn
            color="#6ABAA4"
            :width="$vuetify.breakpoint.xs ? '170px' : '275px'"
            height="44px"
            style="text-transform: unset !important"
            class="white--text mb-3"
            v-if="!signUpSuccess && !loggedUser && typeUser !== 'new_user'"
            @click="openLogInModal"
          >
            {{ "sign_in_heading_sign_in" | localize }}
          </v-btn>
          <v-btn
            color="#6ABAA4"
            :width="$vuetify.breakpoint.xs ? '170px' : '275px'"
            height="44px"
            style="text-transform: unset !important"
            class="white--text mt-3"
            :class="$vuetify.breakpoint.xs ? 'mb-10' : ''"
            v-if="!signUpSuccess && typeUser == 'new_user'"
            @click="typeUser == 'new_user' ? registration() : $emit('done')"
            :loading="isLoading"
          >
            {{
              typeUser == "new_user"
                ? "cart_details_button_create_account"
                : "cart_details_button_next" | localize
            }}
          </v-btn>
        </v-row>
        <loader v-if="showEmailCheckLoader" />
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
          "
          class="px-10"
          v-show="!showEmailCheckLoader"
        >
          <email-check-component
            v-if="signUpSuccess && showEmailCheckComponent"
            @success="done"
            @showLoader="showEmailCheckLoader = true"
            @hideLoader="showEmailCheckLoader = false"
            colorBtn="#6ABAA4"
          />
        </div>
      </v-col>
    </v-row>
    <terms-modal
      v-if="termsModal"
      :rules="'license'"
      @close_modal="closeModal"
    />
    <login-modal v-if="showLogInModal" @close_modal="showLogInModal = false" />
    <confirm-local-modal
      v-if="showConfirmLocalModal"
      :visible="showConfirmLocalModal"
      @choosed="choosedConfirm"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import AuthService from "../../requests/Auth/AuthService";
import EmailCheckComponent from "../Registration/EmailCheckComponent.vue";
import { mapGetters, mapActions } from "vuex";
import termsModal from "@/components/UI/TermsModal.vue";
import WhereToStayService from "../../requests/WhereToStay/WhereToStayService";
import LoginModal from "../UI/LoginModal.vue";
import ListsService from "../../requests/Lists/ListsService";
import CitypassService from "@/requests/Citypass/CitypassService";
import Loader from "../UI/Loader.vue";
import ConfirmLocalModal from "../UI/confirmLocalModal.vue";
export default {
  mixins: [validationMixin],
  data: () => ({
    user: {
      birthDate: "",
      phone_code: "+",
      hotel: "",
      gender: "",
      country: "",
    },
    saveCreditCard: 0,
    typeUser: "new_user",
    termsModal: false,
    isAgree: 0,
    items: [],
    itemsEN: [
      { value: "Male", title: "Male" },
      { value: "Female", title: "Female" },
      { value: "Other", title: "Other" },
    ],
    itemsES: [
      { value: "Male", title: "Hombre" },
      { value: "Female", title: "Mujer" },
      { value: "Other", title: "Otro" },
    ],
    countryList: [],
    userLocale: "",
    menu: "",
    signUpSuccess: false,
    showConfirmLocalModal: false,
    isLoading: false,
    localUser: true,
    authEmailError: [],
    numberError: [],
    showPassword: false,
    showLogInModal: false,
    showLoader: false,
    showEmailCheckComponent: true,
    showEmailCheckLoader: false,
    choseIsAgree: false,
    hotel: [],
    states: [],
    phoneNumberMask: "",
    isMexico: false,
    userCountry: {
      state: "",
      city: "",
    },
  }),
  components: {
    EmailCheckComponent,
    termsModal,
    LoginModal,
    Loader,
    ConfirmLocalModal,
  },
  validations() {
    return {
      user: {
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        phone_code: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(5),
        },
        phone: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(16 - this.user.phone_code.length),
        },
        email: {
          required,
          email,
        },
        country: {
          required,
        },
        password: {
          required,
          minLength: minLength(8),
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /[0-9]/.test(value);
            const containsSpecial = /[\\/#?!@$%^&*-]/.test(value);
            return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial &&
              minLength
            );
          },
        },
      },
      userCountry: {
        state: {
          required,
        },
        city: {
          required,
        },
      },
    };
  },
  mounted() {
    this.loggedUser
      ? ((this.typeUser = "existing_user"), this.$emit("done"))
      : "new_user";
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.users.locale;
    this.items = this.userLocale == "en" ? this.itemsEN : this.itemsES;
    this.getHotels();
    this.getCountry();
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    ...mapActions(["updateInfo"]),
    isNumber(evt) {
      const isNumber = isFinite(evt.key);

      if (!isNumber) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // disable autofill !!!!!
    dynamicID() {
      return "dynamicID-" + Math.floor(Math.random() * Date.now().toString());
    },
    // disable autofill !!!!!
    async getHotels() {
      let response = await WhereToStayService.getBusinessFromCategory(
        "183,384,28"
      );
      this.hotel = response.result;
    },
    async getCountry() {
      let response = await ListsService.getCountry();
      this.countryList = response.data;
    },
    closeModal() {
      this.termsModal = false;
    },
    openModal() {
      this.termsModal = true;
    },
    choosedConfirm(check) {
      this.localUser = check;
      if (check == true) {
        this.userCountry.state = this.states.filter(
          (state) => state.name == "Jalisco"
        )[0].id;
      }
      this.showConfirmLocalModal = false;
    },
    openLogInModal() {
      this.showLogInModal = true;
    },
    registration() {
      this.$v.$touch();
      if (this.isMexico) {
        if (
          !this.$v.$invalid &&
          Object.values(this.phoneNumberError).length == 0 &&
          this.isAgree == 1
        ) {
          this.onRegistration();
        } else if (this.isAgree == 0) {
          this.choseIsAgree = true;
          setTimeout(() => (this.choseIsAgree = false), 1500);
        }
      } else {
        if (
          !this.$v.user.$invalid &&
          Object.values(this.phoneNumberError).length == 0 &&
          this.isAgree == 1
        ) {
          this.onRegistration();
        } else if (this.isAgree == 0) {
          this.choseIsAgree = true;
          setTimeout(() => (this.choseIsAgree = false), 1500);
        }
      }
    },
    async onRegistration() {
      this.isLoading = true;
      let form = new FormData();
      form.append("email", this.user.email);
      form.append("password", this.user.password);
      form.append("first_name", this.user.first_name);
      form.append("last_name", this.user.last_name);
      form.append("phone_code", this.user.phone_code.replace(/\D+/g, ""));
      form.append("phone_num", this.user.phone.replace(/\D+/g, ""));
      form.append("neighbourhood", 1);
      form.append("dob", this.user.birthDate);
      form.append("hotel", this.user.hotel);
      form.append("gender", this.user.gender);
      form.append("country", this.user.country);
      if (this.userCountry.city !== "") {
        form.append("user_city", this.userCountry.city);
      }
      if (this.userCountry.state !== "") {
        form.append("state", this.userCountry.state);
      }
      form.append("type", this.localUser ? "Local" : "Tourist");
      // form.append("isNewUser", true);
      form.append("lang", this.userLocale);
      await AuthService.registration(form)
        .then((res) => {
          if (res.message == "Sorry, but the phone number seems incorrect.") {
            this.isLoading = false;
            this.numberError.push("phone_number_validation");
          }
          this.updateInfo({
            token: res.data.token,
          });
          this.signUpSuccess = true;
        })
        .catch((e) => {
          this.isLoading = false;
          if (e.response.status == 401) {
            if (
              e.response.data.message ==
              "This email has already been used in a previous registration. Please use another."
            )
              this.authEmailError.push("sign_up_email_already_used");
            else if (
              e.response.data.message ==
              "We are already have this phone number for someone else. Please provide another phone number."
            )
              this.numberError.push("sign_up_phone_number_already_used");
          } else if (e.response.status == 400) {
            if (
              e.response.data.message ==
              "This email has already been used in a previous registration. Please use another."
            )
              this.authEmailError.push("sign_up_email_already_used");
            else if (
              e.response.data.message ==
              "We are already have this phone number for someone else. Please provide another phone number."
            )
              this.numberError.push("sign_up_phone_number_already_used");
          }
        });
      this.$emit("create");
    },
    done() {
      this.showEmailCheckComponent = false;
      this.onLogin();
    },
    async onLogin() {
      let form = new FormData();
      form.append("email", this.user.email);
      form.append("password", this.user.password);
      form.append("lang", this.users.locale);
      await AuthService.login(form)
        .then(async (response) => {
          if (response.data.status) {
            this.updateInfoLogged({
              token: response.data.token,
              locale: this.users.locale || "es",
              id: response.data.id,
              user_type: response.data.user_type,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              profile_image: response.data.profile_image,
            });
            if (window.location.href.indexOf("dev") == -1) {
              await CitypassService.checkAdmin()
                .then(() => {
                  this.updateInfoLogged({
                    admin: true,
                  });
                })
                .catch(() => {
                  this.showLoader = false;
                  this.updateInfoLogged({
                    admin: false,
                  });
                });
            } else {
              this.updateInfoLogged({
                admin: true,
              });
            }
            if (window.location.href.indexOf("localhost") > -1) {
              this.updateInfoLogged({
                admin: true,
              });
            }
          }
          this.typeUser = "existing_user";
          this.$emit("done");
          this.showEmailCheckLoader = false;
        })
        .catch((e) => {
          this.showEmailCheckLoader = false;
          this.message = e.response.data.message;
        });
    },
    async getStates() {
      await ListsService.getState(this.user.country).then((res) => {
        this.states = res.data;
      });
    },
  },
  watch: {
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.users.locale;
        this.items = this.userLocale == "en" ? this.itemsEN : this.itemsES;
        this.getCountry();
      },
    },
    "users.locale": {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser
          ? this.loggedUser.locale
          : this.users.locale;
        this.items = this.userLocale == "en" ? this.itemsEN : this.itemsES;
        this.getCountry();
      },
    },
    loggedUser: {
      deep: true,
      handler() {
        if (this.loggedUser) {
          this.$emit("done");
        }
      },
    },
    typeUser: {
      deep: true,
      handler() {
        this.$emit("notDone");
        if (this.typeUser == "existing_user") {
          this.loggedUser ? this.$emit("done") : "";
        }
      },
    },
    "user.phone_code": {
      deep: true,
      handler() {
        let quantitySymbol =
          this.user.phone_code.length > 1
            ? 16 - this.user.phone_code.length
            : 15 - this.user.phone_code.length;
        this.phoneNumberMask = "";
        for (let index = 0; index < quantitySymbol; index++) {
          this.phoneNumberMask += "#";
        }
      },
    },
    "user.country": {
      handler() {
        this.user.hotel = "";
        if (this.user.country !== "") {
          let country = this.countryList.filter(
            (country) => country.id == this.user.country
          );
          if (this.user.country !== undefined && this.user.country !== "") {
            if (country[0].have_states == "Yes") {
              this.getStates();
            }
            this.userCountry = {
              state: "",
              city: "",
            };
          }
          if (
            country[0].name.includes("México") ||
            country[0].name.includes("Mexico")
          ) {
            this.showConfirmLocalModal = true;
            this.isMexico = true;
          } else {
            this.localUser = false;
            this.isMexico = false;
          }
        }
      },
    },
    "userCountry.state": {
      handler() {
        let state = this.states.filter(
          (state) => state.id == this.userCountry.state
        )[0];
        if (state.name !== "Jalisco") {
          this.localUser = false;
        } else {
          this.localUser = true;
        }
      },
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters({ users: "user" }),
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.user.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    // confirmEmailError() {
    //   const errors = [];
    //   if (!this.$v.user.confirm_email.$dirty) {
    //     return errors;
    //   }
    //   if (!this.$v.user.confirm_email.sameAsEmail) {
    //     errors.push("");
    //     return errors;
    //   }
    //   !this.$v.user.confirm_email.required && errors.push("");
    //   return errors;
    // },
    firstNameError() {
      const errors = [];
      if (!this.$v.user.first_name.$dirty) {
        return errors;
      }
      !this.$v.user.first_name.required &&
        errors.push("sign_up_validation_first_name_required");
      return errors;
    },
    lastNameError() {
      const errors = [];
      if (!this.$v.user.last_name.$dirty) {
        return errors;
      }
      !this.$v.user.last_name.required &&
        errors.push("sign_up_validation_last_name_required");
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }

      if (this.$v.user.phone.$invalid) {
        errors.push("sign_up_validation_phone_number_required");
      }

      if (this.$v.user.phone.$invalid) {
        if (!this.$v.user.phone.minLength) {
          errors.push("sign_up_validation_phone_number_incorrect");
        }
        if (!this.$v.user.phone.maxLength) {
          errors.push("sign_up_validation_phone_number_incorrect");
        }
      }
      return errors;
    },
    phoneCodeError() {
      const errors = [];
      if (!this.$v.user.phone_code.$dirty) {
        return errors;
      } else if (!this.$v.user.phone_code.required) {
        errors.push("sign_up_validation_phone_code_required");
        return errors;
      }
      if (!this.$v.user.phone_code.maxLength) {
        errors.push("sign_up_validation_phone_number_incorrect");
      }
      if (!this.$v.user.phone_code.minLength) {
        errors.push("sign_up_validation_phone_code_required");
      }
      return errors;
    },
    countryError() {
      const errors = [];
      if (!this.$v.user.country.$dirty) {
        return errors;
      }
      !this.$v.user.country.required &&
        errors.push("sign_up_validation_country_required");
      return errors;
    },
    stateError() {
      const errors = [];
      if (!this.$v.userCountry.state.$dirty) {
        return errors;
      }
      !this.$v.userCountry.state.required &&
        errors.push("sign_up_mexico_state_validation");
      return errors;
    },
    cityError() {
      const errors = [];
      if (!this.$v.userCountry.city.$dirty) {
        return errors;
      }
      !this.$v.userCountry.city.required &&
        errors.push("sign_up_mexico_city_validation");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      if (!this.$v.user.password.required) {
        errors.push("sign_up_validation_password_required");
        return errors;
      }
      if (
        (!this.$v.user.password.valid && this.$v.user.password.required) ||
        (!this.$v.user.password.minLength && this.$v.user.password.required)
      ) {
        errors.push("password_hint");
        return errors;
      }
      return errors;
    },
  },
};
</script>

<style>
.req {
  color: red;
}
.aggreEULAText {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
@media only screen and (max-width: 325px) {
  .ragioGroup {
    padding-left: 20px !important;
  }
}

@media only screen and (min-width: 325px) and (max-width: 375px) {
  .ragioGroup {
    padding-left: 50px !important;
  }
}

@keyframes horizontal-snakign {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
