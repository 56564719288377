<template>
  <v-col style="text-align: center">
    <div
      :style="
        $vuetify.breakpoint.xs
          ? 'width: 260px;border-top-left-radius: 5%; border-top-right-radius: 5%; background-color:' +
            order.color
          : 'width: 290px;border-top-left-radius: 5%; border-top-right-radius: 5%; background-color:' +
            order.color
      "
      class="white--text pb-3"
    >
      <v-row no-gutters align="center" justify="center" class="pt-6">
        <v-divider class="white mx-3" />
        <img
          :src="require('@/assets/' + $options.filters.city('white_main_logo'))"
          class="pass-logo"
          :class="{ 'pass-logo-mzt': mazatlan }"
        />
        <v-divider class="white mx-3" />
      </v-row>
      <h3
        class="font-weight-bold pt-3"
        style="font-style: normal; font-weight: 800; font-size: 21px"
      >
        {{ locale == "en" ? order.cityPassTitleEn : order.cityPassTitleEs }}
      </h3>
      <span class="py-0">{{ "city_passes_label_city_pass" | localize }}</span>
      <br />
      <!-- Days for other screen -->
      <div class="orderDaysLocal" v-if="order.days && !$vuetify.breakpoint.xs">
        <h3 v-if="order.days == '1,2,3,4,5'">
          {{ "city_passes_Monday" | localize }} -
          {{ "city_passes_Friday" | localize }}
        </h3>
        <h3 v-else-if="order.days == '1,2,3,4,5,6,7'">
          {{ "city_passes_Monday" | localize }} -
          {{ "city_passes_Sunday" | localize }}
        </h3>
        <h3 v-else-if="order.days == '1,2,3,4'">
          {{ "city_passes_Monday" | localize }} -
          {{ "city_passes_Thursday" | localize }}
        </h3>
        <h3 v-else-if="order.days == '5,6,7'">
          {{ "city_passes_Friday" | localize }} -
          {{ "city_passes_Sunday" | localize }}
        </h3>
        <h3 v-else-if="order.days == '6,7'">
          {{ "city_passes_Saturday" | localize }} -
          {{ "city_passes_Sunday" | localize }}
        </h3>
        <h3 v-else-if="order.days == '28'">
          {{ "city_passes_label_month" | localize }}
        </h3>
        <h3 v-else>
          {{ order.days }}&nbsp;{{ "city_passes_label_days" | localize }}
        </h3>
      </div>
      <!-- Days for other screen -->
      <!-- Days for mobile screen -->
      <div class="orderDaysLocal" v-if="order.days && $vuetify.breakpoint.xs">
        <h4 v-if="order.days == '1,2,3,4,5'">
          {{ "city_passes_Monday" | localize }} -
          {{ "city_passes_Friday" | localize }}
        </h4>
        <h4 v-else-if="order.days == '1,2,3,4,5,6,7'">
          {{ "city_passes_Monday" | localize }} -
          {{ "city_passes_Sunday" | localize }}
        </h4>
        <h4 v-else-if="order.days == '1,2,3,4'">
          {{ "city_passes_Monday" | localize }} -
          {{ "city_passes_Thursday" | localize }}
        </h4>
        <h4 v-else-if="order.days == '5,6,7'">
          {{ "city_passes_Friday" | localize }} -
          {{ "city_passes_Sunday" | localize }}
        </h4>
        <h4 v-else-if="order.days == '6,7'">
          {{ "city_passes_Saturday" | localize }} -
          {{ "city_passes_Sunday" | localize }}
        </h4>
        <h4 v-else-if="order.days == '28'">
          {{ "city_passes_label_month" | localize }}
        </h4>
        <h4 v-else>
          {{ order.days }}&nbsp;{{ "city_passes_label_days" | localize }}
        </h4>
      </div>
      <!-- /Days for mobile screen -->
      <div>
        <span class="white--text">
          {{
            new Date(order.started_at).toLocaleDateString("en-gb")
          }}
          -
          {{
            order.end_date.toLocaleDateString("en-gb")
          }}
        </span>
      </div>
    </div>
    <div
      style="
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      "
      :style="$vuetify.breakpoint.xs ? 'width: 260px' : 'width:290px'"
      class="px-1"
    >
      <v-col class="px-0">
        <v-row
          align="center"
          v-for="number in order.number"
          :key="number.id"
          class="mt-1 px-3"
        >
          <span v-if="number.number > 0"
            >{{ number.number }}x {{ number.type | localize }}</span
          >
          <v-spacer></v-spacer>
          <span v-if="number.number > 0 && mazatlan"
            >MX$
            {{
              new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
              }).format(number.price)
            }}</span
          >
        </v-row>
        <div class="pt-4" style="text-align: left">
          <span>{{ "infants_label" | localize }}</span>
        </div>
        <div class="mr-3 mt-5">
          <!-- <v-row no-gutters align="center" class="mt-5">
            <span>{{ "attractions_starting_date" | localize }}</span
            ><v-row justify="end"
              ><span>{{
                new Date(order.started_at).toLocaleDateString("en-EN", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              }}</span></v-row
            ></v-row
          > -->
          <v-row no-gutters align="center">
            <!-- <span>Booking Fee</span
        ><v-row justify="end"
          ><span
            >MX$ {{
              new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
              }).format(order.bookingFee)
            }}</span
          ></v-row> -->
          </v-row>
          <v-row no-gutters align="center" v-if="mazatlan">
            <span>
              {{ "banking_charges_label" | localize }}
            </span>
            <v-row justify="end">
              <span>
                MX$
                {{
                  new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                  }).format(order.fee)
                }}
              </span>
            </v-row>
          </v-row>
          <v-row
            no-gutters
            align="center"
            v-if="order.additional && order.additional !== 0"
          >
            <v-col
              :class="mazatlan ? 'col-6' : 'col-12'"
              style="text-align: left"
            >
              <span>
                {{ `${mazatlan ? "mzt_" : ""}bus_metro_label` | localize
                }}<br />{{ "public_transport_label2" | localize }}
              </span>
            </v-col>
            <v-row justify="end" v-if="mazatlan">
              <span>
                MX$
                {{
                  new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                  }).format(order.additional)
                }}
              </span>
            </v-row>
          </v-row>
          <v-row no-gutters align="center">
            <span style="font-weight: bold">{{
              "cart_label_total_cost" | localize
            }}</span
            ><v-row justify="end"
              ><span style="font-weight: bold"
                >MX$
                {{
                  new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                  }).format(order.subtotal)
                }}</span
              ></v-row
            >
          </v-row>
        </div>
      </v-col>
    </div>
  </v-col>
</template>

<script>
import cityMixin from "@/mixins/cityMixin";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";

export default {
  mixins: [cityMixin, userSettingsMixin],
  props: {
    order: {
      require: true,
    },
  },
};
</script>

<style>
.orderDays {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
}
.numberSpan {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
</style>