<template>
  <div :style="$vuetify.breakpoint.xs ? 'width: 100%;' : 'width: 50%;'">
    <!-- Joel corrections -->
    <!-- <v-row class="mb-2" justify="center" align="center">
      <span
        class="mr-2"
        :style="step == 1 ? 'color: #e62076' : 'color: black'"
        :class="step == 1 ? 'stepActive' : 'stepInActive'"
        >1.
        <span
          :style="step == 1 ? 'color: #E62076' : 'color: #7a7a7a'"
          :class="step == 1 ? 'stepActive' : 'stepInActive'"
          >{{ "cart_details_label_customer_details" | localize }}</span
        ></span
      >
      <span
        :style="step == 2 ? 'color: #e62076' : 'color: black'"
        :class="step == 2 ? 'stepActive' : 'stepInActive'"
        >2.
        <span
          :style="step == 2 ? 'color: #E62076' : 'color: #7a7a7a'"
          :class="step == 2 ? 'stepActive' : 'stepInActive'"
          >{{ "cart_details_label_billing_address" | localize }}</span
        ></span
      >
    </v-row> -->
    <v-row justify="end">
      <v-col
        :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width:600px'"
        :class="$vuetify.breakpoint.xs ? '' : ''"
      >
        <checkout-create-user-form
          :class="$vuetify.breakpoint.xs ? '' : 'ml-15'"
          v-if="step == 1"
          @done="done"
          @notDone="$emit('notDone')"
        />
        <!-- @create="step = 2" -->
        <!-- Joel corrections -->
        <!-- <checkout-billing-address-form
          :class="$vuetify.breakpoint.xs ? '' : 'ml-15'"
          v-if="step == 2"
          @done="setBillingAddressForOrder"
          @notDone="$emit('notDone')"
        /> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import checkoutCreateUserForm from "./checkoutCreateUserForm.vue";
// import checkoutBillingAddressForm from "./checkoutBillingAddressForm.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    checkoutCreateUserForm,
    //checkoutBillingAddressForm,
  },
  data: () => ({
    step: 1,
    order: {},
    userCards: [],
  }),
  mounted() {
    this.userCards =
      localStorage.getItem("cards") === null
        ? []
        : JSON.parse(localStorage.getItem("cards"));
    this.userCards = this.userCards.filter((card) => {
      return card.token.includes(this.loggedUser.token);
    });
  },
  methods: {
    // setBillingAddressForOrder(biilingAddress) {
    //   (this.order.country = biilingAddress.country),
    //     (this.order.street = biilingAddress.street),
    //     (this.order.address = biilingAddress.address || ""),
    //     (this.order.state = biilingAddress.state),
    //     (this.order.zip = biilingAddress.zip);
    //   this.order.lang = this.loggedUser
    //     ? this.loggedUser.locale
    //     : this.user.locale;
    //   this.$emit("done", this.order);
    // },
    done() {
      this.order.lang = this.loggedUser
        ? this.loggedUser.locale
        : this.user.locale;
      this.$emit("done", this.order);
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped>
.credit_card_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: black;
}
.stepActive {
  font-weight: 700;
  font-size: 16px;
}
.stepInActive {
  font-weight: 400;
  font-size: 16px;
  color: #212121;
}
</style>