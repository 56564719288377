<template>
  <div :class="$vuetify.breakpoint.xs ? '' : 'order_detail'">
    <v-row
      justify="start"
      no-gutters
      :class="$vuetify.breakpoint.xs ? '' : 'px-15'"
    >
      <v-col>
        <h3
          :class="$vuetify.breakpoint.xs ? 'mt-0 mb-5' : 'ml-15 mb-2'"
          :style="$vuetify.breakpoint.xs ? ' text-align:center;' : ''"
        >
          {{ "cart_details_label_cart_details" | localize }}
        </h3>
        <v-row :justify="$vuetify.breakpoint.xs ? 'center' : 'center'">
          <v-col>
            <checkout-order-detail-list
              @deleteCitypassFromCart="deleteCitypassFromCart"
              :order="order"
            />
          </v-col>
        </v-row>
        <div v-if="order.length > 0 && isDone">
          <div :class="$vuetify.breakpoint.xs ? 'px-1 mt-5' : 'px-12 mt-5'">
            <v-divider />
          </div>
          <v-col :class="$vuetify.breakpoint.xs ? 'px-1' : 'px-14'">
            <v-row no-gutters align="center" class="mt-2">
              <span style="font-weight: bold">
                {{ "attractions_order_total" | localize }}
              </span>
              <v-row
                justify="end"
                :class="$vuetify.breakpoint.xs ? 'mr-1' : ''"
              >
                <span style="font-weight: bold">
                  MX$
                  {{
                    new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                    }).format(orderTotal)
                  }}
                </span>
              </v-row>
            </v-row>
          </v-col>
          <v-row
            align="center"
            justify="center"
            :class="
              $vuetify.breakpoint.xs && userLocale == 'en'
                ? 'mt-2 mb-1 px-2'
                : !$vuetify.breakpoint.xs && userLocale == 'es'
                ? 'px-5 mt-2 mb-1 ml-2'
                : 'px-13 mt-2 mb-1 ml-2'
            "
          >
            <div style="width: 40px">
              <img
                class="mr-1"
                src="@/assets/notChecked.svg"
                style="cursor: pointer"
                v-if="isAgree == 0"
                @click="isAgree = 1"
              />
              <img
                src="@/assets/chekedTerm.svg"
                v-if="isAgree == 1"
                class="mr-1"
                @click="isAgree = 0"
                style="cursor: pointer"
              />
            </div>
            <v-col
              cols="8"
              md="9"
              lg="9"
              sm="9"
              xl="9"
              class="px-0"
              align-self="center"
            >
              <span
                style="font-weight: 500; font-size: 14px; line-height: 17px"
              >
                {{ "cart_details_label_agree_terms_conditions1" | localize }}
                <br />
                <span
                  @click="openModal()"
                  style="
                    color: black;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                >
                  {{ "cart_details_label_agree_terms_conditions2" | localize }}
                </span>
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters class="ml-2">
            <v-btn
              :width="$vuetify.breakpoint.xs ? '220px' : '320px'"
              style="text-transform: unset !important"
              class="white--text mt-5"
              color="#E62076"
              :disabled="!isDone || isAgree == 0"
              @click="checkout"
            >
              <span>
                {{ "cart_details_button_confirm_order_pay1" | localize }}
                <br />
                {{ "cart_details_button_confirm_order_pay2" | localize }}
              </span>
            </v-btn>
          </v-row>
          <v-row no-gutters justify="center" class="mt-5">
            <img height="25px" src="@/assets/paymentMethodLogo.png" />
          </v-row>
        </div>
      </v-col>
    </v-row>
    <terms-modal v-if="termsModal" :rules="'terms'" @close_modal="closeModal" />
  </div>
</template>

<script>
import checkoutOrderDetailList from "@/components/Checkout/checkoutOrderDetailList";
import CartService from "../../requests/Cart/CartService";
import CitypassService from "../../requests/Citypass/CitypassService";
import termsModal from "@/components/UI/TermsModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    checkoutOrderDetailList,
    termsModal,
  },
  data: () => ({
    totalPrice: 90,
    discount: 20,
    bookingFee: 0,
    isAgree: 0,
    order: [],
    orderTotal: 0,
    termsModal: false,
    userLocale: "",
  }),
  props: {
    isDone: {
      require: true,
    },
  },
  mounted() {
    this.getCart();
    if (this.loggedUser) {
      this.userLocale = this.loggedUser.locale;
    } else {
      this.userLocale = this.user.locale;
    }
  },
  methods: {
    closeModal() {
      this.termsModal = false;
    },
    openModal() {
      this.termsModal = true;
    },
    async getCart() {
      let cart = await CartService.getCart();

      let response = cart.result.list;
      // if (response.length == 0 && this.$route.name == 'Checkout') {
      //   setTimeout(() => this.$router.push('/attractions'), 5000)
      // }
      this.orderTotal = cart.result.total;
      localStorage.count = response.length;
      let temporaryArray = [];
      for (let i = 0; i < response.length; i++) {
        let citypass = await this.getCitypass(response[i].citypass_uuid);
        temporaryArray.push({
          uuid: response[i].uuid,
          citypass_uuid: response[i].citypass_uuid,
          cityPassTitle: citypass.title_en,
          cityPassTitleEn: citypass.title_en,
          cityPassTitleEs: citypass.title_es,
          type: citypass.type,
          days: citypass.days !== "" ? citypass.days : citypass.term,
          end_date: this.addDays(
            response[i].started_at,
            citypass.term,
            citypass.days
          ),
          started_at: response[i].started_at,
          fee: response[i].fee,
          additional: response[i].additional,
          color: citypass.color,
          number: [
            {
              id: 1,
              number: response[i].quantity_adult,
              type: "attractions_adult",
              price: citypass.price_adult,
            },
            {
              id: 2,
              number: response[i].quantity_kid,
              type: "cart_label_child",
              price: citypass.price_kid,
            },
          ],
          subtotal: response[i].total,
        });
      }
      this.order = temporaryArray;
      this.showLoader = false;
    },
    async deleteCitypassFromCart(uuid) {
      await CartService.deleteFromCart(uuid);
      this.getCart();
    },
    async getCitypass(uuid) {
      let citypass = [];
      await CitypassService.getCitypass(uuid).then((response) => {
        citypass = response.result;
      });
      return citypass;
    },
    checkout() {
      this.$emit("checkout", this.userLocale);
    },
    addDays(theDate, days, week) {
      let endDate = new Date(theDate);
      if (week && week != "1,2,3,4,5,6,7") {
        let day = week.substr(-1) - endDate.getDay();
        endDate.setDate(endDate.getDate() + parseInt(day));
      } else {
        endDate.setDate(endDate.getDate() + parseInt(days) - 1);
      }

      return endDate;
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    loggedUser: {
      deep: true,
      handler() {
        this.userLocale = this.loggedUser.locale;
      },
    },
    user: {
      deep: true,
      handler() {
        this.userLocale = this.user.locale;
      },
    },
  },
};
</script>

<style>
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #212121;
}
.order_detail {
  border-left: 2px solid #e62076;
}
</style>
