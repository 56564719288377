<template>
  <div>
    <v-col>
      <v-row
        v-for="order in order"
        :key="order.id"
        justify="center"
        align="start"
      >
        <div v-if="order.type == '0'" class="mb-5">
          <v-row
            :justify="$vuetify.breakpoint.xs ? 'center' : ''"
          >
            <checkout-tourist-card :order="order" />
          </v-row>
        </div>
        <div v-if="order.type == '1'" class="mb-5">
          <v-row
            :justify="$vuetify.breakpoint.xs ? 'center' : ''"
          >
            <checkout-local-card :order="order" />
          </v-row>
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import checkoutLocalCard from "@/components/Checkout/checkoutLocalCard";
import checkoutTouristCard from "./checkoutTouristCard.vue";
export default {
  components: {
    checkoutLocalCard,
    checkoutTouristCard,
  },
  props: {
    order: {
      require: true,
    },
  },
};
</script>

<style>
</style>