<template>
  <div>
    <v-col class="py-0">
      <v-row
        v-if="!checkoutSuccess"
        :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
        no-gutters
      >
        <checkout-paymet @done="done" @notDone="isDone = false" />
        <checkout-order-detail :isDone="isDone" @checkout="checkoutOrder" />
      </v-row>
    </v-col>
  </div>
</template>

<script>
import checkoutOrderDetail from "./checkoutOrderDetail.vue";
import checkoutPaymet from "./checkoutPaymet.vue";
import CartService from "../../requests/Cart/CartService";
export default {
  components: {
    checkoutOrderDetail,
    checkoutPaymet,
  },
  data: () => ({
    checkoutSuccess: false,
    isDone: false,
    order: {},
  }),
  mounted() {
    this.$vuetify.goTo(0, 0);
  },
  methods: {
    async checkoutOrder() {
      this.isDone = false;
      let response = await CartService.createOrder(this.order);
      window.location.replace(response.result.payment.redirect_url);
      this.checkoutSuccess = true;
      localStorage.count = 0;
      this.$emit("success");
    },
    done(order) {
      this.order = order;
      this.isDone = true;
    },
  },
  /* watch: {
    checkoutSuccess: {
      deep: true,
      handler(){
        if(this.checkoutSuccess == true){
          this.$emit('success');
        }
      }
    }
  }*/
};
</script>

<style>
</style>